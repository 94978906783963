@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100;0,9..144,200;0,9..144,300;0,9..144,400;0,9..144,500;0,9..144,600;0,9..144,700;0,9..144,800;0,9..144,900;1,9..144,100;1,9..144,200;1,9..144,300;1,9..144,400;1,9..144,500;1,9..144,600;1,9..144,700;1,9..144,800;1,9..144,900&family=Fuzzy+Bubbles:wght@400;700&family=Lobster&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//FONT-FAMILY
$font-family-sans-serif: 'Source Sans 3', sans-serif;
$font-family-serif:      'Source Sans 3', sans-serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;
$headings-font-family: 'Fraunces', serif;

//COLORS
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #2D2D2D;
$black:    #000;



$blue:    #000F46;
$blue-75:    #404B74;
$blue-50:    #8087A2;
$blue-25:    #BFC3D1;
$blue-dark:    #000b34;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #EB7BBE;
$pink-75:    #F09CCE;
$pink-50:    #F5BDDF;
$pink-25:    #FADEEF;
$pink-dark:    #73234B;
$pink-dark-75:    #9A4049;
$pink-dark-50:    #BB8086;
$pink-dark-25:    #DDBFC2;
$red:     #FF2D3C;
$red-75:     #FF616D;
$red-50:     #FF969D;
$red-25:     #FFCACE;
$red-dark:     #78000D;
$red-dark-75:     #9A4049;
$red-dark-50:     #BB8086;
$red-dark-25:     #DDBFC2;
$orange:  #fd7e14;
$yellow:  #FFD629;
$yellow-75:  #FFE05E;
$yellow-50:  #FFEA94;
$yellow-25:  #FFF5C9;
$yellow-dark:  #A84500;
$yellow-dark-75:  #BE7440;
$yellow-dark-50:  #D4A280;
$yellow-dark-25:  #E9D1BF;
$green:   #9FB825;
$green-75:   #B7CA5B;
$green-50:   #CFDC92;
$green-25:   #E7EDC8;
$green-dark:   #2C421D;
$green-dark-75:   #617156;
$green-dark-50:   #95A08E;
$green-dark-25:   #CAD0C6;
$teal:    #ABC1A7;
$teal-75:    #C0D0BD;
$teal-50:    #D5E0D3;
$teal-25:    #EAEFE9;
$teal-dark:    #444A40;
$teal-dark-75:    #737770;
$teal-dark-50:    #A2A49F;
$teal-dark-25:    #D0D2CF;
$cyan:    #46C8F0;
$cyan-75:    #74D6F4;
$cyan-50:    #A3E4F7;
$cyan-25:    #D1F1FB;
$cyan-dark:    #003C55;
$cyan-dark-75:    #406D80;
$cyan-dark-50:    #809DAA;
$cyan-dark-25:    #BFCED5;
$magpie:    #C8C8C8;
$magpie-75:    #D6D6D6;
$magpie-50:    #E4E4E4;
$magpie-25:    #F1F1F1;
$magpie-dark:    #2D2D2D;
$magpie-dark-75:    #616161;
$magpie-dark-50:    #969696;
$magpie-dark-25:    #CACACA;

$colors: (
  "blue": $blue,
  "blue-75": $blue-75,
  "blue-50": $blue-50,
  "blue-25": $blue-25,
  "blue-dark": $blue-dark,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "pink-75": $pink-75,
  "pink-50": $pink-50,
  "pink-25": $pink-25,
  "pink-dark": $pink-dark,
  "pink-dark-75": $pink-dark-75,
  "pink-dark-50": $pink-dark-50,
  "pink-dark-25": $pink-dark-25,
  "red": $red,
  "red-75": $red-75,
  "red-50": $red-50,
  "red-25": $red-25,
  "red-dark": $red-dark,
  "red-dark-75": $red-dark-75,
  "red-dark-50": $red-dark-50,
  "red-dark-25": $red-dark-25,
  "orange": $orange,
  "yellow": $yellow,
  "yellow-75": $yellow-75,
  "yellow-50": $yellow-50,
  "yellow-25": $yellow-25,
  "yellow-dark": $yellow-dark,
  "yellow-dark-75": $yellow-dark-75,
  "yellow-dark-50": $yellow-dark-50,
  "yellow-dark-25": $yellow-dark-25,
  "green": $green,
  "green-75": $green-75,
  "green-50": $green-50,
  "green-25": $green-25,
  "green-dark": $green-dark,
  "green-dark-75": $green-dark-75,
  "green-dark-50": $green-dark-50,
  "green-dark-25": $green-dark-25,
  "teal": $teal,
  "teal-75": $teal-75,
  "teal-50": $teal-50,
  "teal-25": $teal-25,
  "teal-dark": $teal-dark,
  "teal-dark-75": $teal-dark-75,
  "teal-dark-50": $teal-dark-50,
  "teal-dark-25": $teal-dark-25,
  "cyan": $cyan,
  "cyan-75": $cyan-75,
  "cyan-50": $cyan-50,
  "cyan-25": $cyan-25,
  "cyan-dark": $cyan-dark,
  "cyan-dark-75": $cyan-dark-75,
  "cyan-dark-50": $cyan-dark-50,
  "cyan-dark-25": $cyan-dark-25,
  "magpie": $magpie,
  "magpie-75": $magpie-75,
  "magpie-50": $magpie-50,
  "magpie-25": $magpie-25,
  "magpie-dark": $magpie-dark,
  "magpie-dark-75": $magpie-dark-75,
  "magpie-dark-50": $magpie-dark-50,
  "magpie-dark-25": $magpie-dark-25,
  "black": $black,
  "white": $white,
  "gray": $gray-600,
);

$primary:       $blue;
$primary-75:       $blue-75;
$primary-50:       $blue-50;
$primary-25:       $blue-25;

$secondary:     $blue-dark;
$secondary-75:     $cyan-dark-75;
$secondary-50:     $cyan-dark-50;
$secondary-25:     $cyan-dark-25;

$success:       $green;
$success-75:       $green-75;
$success-50:       $green-50;
$success-25:       $green-25;
$success-dark:       $green-dark;
$success-dark-75:       $green-dark-75;
$success-dark-50:       $green-dark-50;
$success-dark-25:       $green-dark-25;

$info:          $cyan;
$info-75:          $cyan-75;
$info-50:          $cyan-50;
$info-25:          $cyan-25;
$info-dark:          $cyan-dark;
$info-dark-75:          $cyan-dark-75;
$info-dark-50:          $cyan-dark-50;
$info-dark-25:          $cyan-dark-25;

$warning:       $yellow;
$warning-75:       $yellow-75;
$warning-50:       $yellow-50;
$warning-25:       $yellow-25;
$warning-dark:       $yellow-dark;
$warning-dark-75:       $yellow-dark-75;
$warning-dark-50:       $yellow-dark-50;
$warning-dark-25:       $yellow-dark-25;

$danger:        $red;
$danger-75:        $red-75;
$danger-50:        $red-50;
$danger-25:        $red-25;
$danger-dark:        $red-dark;
$danger-dark-75:        $red-dark-75;
$danger-dark-50:        $red-dark-50;
$danger-dark-25:        $red-dark-25;

$teal:        $teal;
$teal-75:        $teal-75;
$teal-50:        $teal-50;
$teal-25:        $teal-25;
$teal-dark:        $teal-dark;
$teal-dark-75:        $teal-dark-75;
$teal-dark-50:        $teal-dark-50;
$teal-dark-25:        $teal-dark-25;

$pink:        $pink;
$pink-75:        $pink-75;
$pink-50:        $pink-50;
$pink-25:        $pink-25;
$pink-dark:        $pink-dark;
$pink-dark-75:        $pink-dark-75;
$pink-dark-50:        $pink-dark-50;
$pink-dark-25:        $pink-dark-25;

$light:         $magpie;
$light-75:         $magpie-75;
$light-50:         $magpie-50;
$light-25:         $magpie-25;

$dark:          $magpie-dark;
$dark-75:          $magpie-dark-75;
$dark-50:          $magpie-dark-50;
$dark-25:          $magpie-dark-25;

$theme-colors: (
  "primary":    $primary,
  "primary-75":    $primary-75,
  "primary-50":    $primary-50,
  "primary-25":    $primary-25,
  "primary-dark":    $blue-dark,
  "pink":       $pink,
  "pink-75":       $pink-75,
  "pink-50":       $pink-50,
  "pink-25":       $pink-25,
  "pink-dark":  $pink-dark,
  "pink-dark-75":  $pink-dark-75,
  "pink-dark-50":  $pink-dark-50,
  "pink-dark-25":  $pink-dark-25,
  "secondary":  $secondary,
  "secondary-75":  $secondary-75,
  "secondary-50":  $secondary-50,
  "secondary-25":  $secondary-25,
  "success":    $success,
  "success-75":    $success-75,
  "success-50":    $success-50,
  "success-25":    $success-25,
  "success-dark":    $success-dark,
  "success-dark-75":    $success-dark-75,
  "success-dark-50":    $success-dark-50,
  "success-dark-25":    $success-dark-25,
  "info":       $info,
  "info-75":       $info-75,
  "info-50":       $info-50,
  "info-25":       $info-25,
  "info-dark":       $info-dark,
  "info-dark-75":       $info-dark-75,
  "info-dark-50":       $info-dark-50,
  "info-dark-25":       $info-dark-25,
  "teal":       $teal,
  "teal-75":       $teal-75,
  "teal-50":       $teal-50,
  "teal-25":       $teal-25,
  "teal-dark":       $teal-dark,
  "teal-dark-75":       $teal-dark-75,
  "teal-dark-50":       $teal-dark-50,
  "teal-dark-25":       $teal-dark-25,
  "warning":    $warning,
  "warning-75":    $warning-75,
  "warning-50":    $warning-50,
  "warning-25":    $warning-25,
  "warning-dark":    $warning-dark,
  "warning-dark-75":    $warning-dark-75,
  "warning-dark-50":    $warning-dark-50,
  "warning-dark-25":    $warning-dark-25,
  "danger":     $danger,
  "danger-75":     $danger-75,
  "danger-50":     $danger-50,
  "danger-25":     $danger-25,
  "danger-dark":     $danger-dark,
  "danger-dark-75":     $danger-dark-75,
  "danger-dark-50":     $danger-dark-50,
  "danger-dark-25":     $danger-dark-25,
  "light":      $light,
  "light-75":      $light-75,
  "light-50":      $light-50,
  "light-25":      $light-25,
  "dark":       $dark,
  "dark-75":       $dark-75,
  "dark-50":       $dark-50,
  "dark-25":       $dark-25,
);

//ACCORDION
$accordion-button-active-bg:$primary;
$accordion-button-active-color:$white;
$accordion-icon-active-color:$white;

//NAVBAR
$nav-link-font-size:1.125rem;
$nav-link-font-weight:400;
$nav-link-color:$white;
$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='#{$white}'/%3E%3C/svg%3E");
$breadcrumb-divider-color: $white;
$breadcrumb-active-color: $white;

//BTN
$btn-hover-bg-tint-amount:        35%;
$btn-hover-border-tint-amount:    35%;

//LINK
$link-hover-decoration:underline !important;

@import "../node_modules/bootstrap/scss/bootstrap.scss";
//CUSTOM
.frances-font{
    font-family: $headings-font-family;
}

.source-font{
    font-family: $font-family-sans-serif;
}
.p-25{
    padding: 0.6rem !important;
}
//NABARCUSTOM
.navbar-brand{
  align-items: center;
  display: flex;
}
.text-brand{
  font-size: 1.25rem;
  font-weight: 600 !important; 
  line-height: 20px;
}
.top-menu-font-size-l{
  font-size: 1.125rem;
}
.top-menu-font-size{
  font-size: .875rem;
}
.no-underline{
  text-decoration: none;
}
.over_info:hover{
  color: $info !important;
}
.dropdown-menu-right {
  right: 0;
  left: auto !important;
}
.btn-primary:hover{
  background-color: $info;
  color: $primary;
  border-color: $info;
}
.btn-secondary:hover{
  background-color: $info;
  color: $primary;
  border-color: $info;
}
// @function tint-color($base-color, $percentage) {
//     @return mix(white, $base-color, $percentage);
//   }

//   @each $name, $color in $colors {
//     @for $i from 25 through 75 {
//       $tinted-color: tint-color($color, percentage($i / 100));
      
//       .color-#{$name}-#{$i} {
//         color: $tinted-color;
//       }
//     }
//   }